 export function loadBMap(callback) {
      var script = document.createElement('script')
      script.src = 'https://api.map.baidu.com/api?v=2.0&ak=BiaLGa6spqU7dBsvGrCc5fvvUVjriKmd&callback=' + callback
      document.body.appendChild(script)
   }

  export function myBMapInit () {
    const AK = "BiaLGa6spqU7dBsvGrCc5fvvUVjriKmd"; //你的AK
    const BMap_URL = "https://api.map.baidu.com/api?v=2.0&ak=" +AK +"&s=1&callback=onBMapCallback";
    return new Promise((resolve, reject) => {
      // 如果已加载直接返回
      if (typeof BMap !== "undefined") {
        resolve(BMap);
        return true;
      }
      // 百度地图异步加载回调处理
      window.onBMapCallback = function() {
        resolve(BMap);
      };

      // 插入script脚本
      let scriptNode = document.createElement("script");
      // scriptNode.setAttribute(type, 'text/javascript');
      scriptNode.setAttribute("src", BMap_URL);
      document.body.appendChild(scriptNode);
    });
  }
 
  /**
 * 预处理判断 某个点是否在某个区域
 * @param {*} lng 
 * @param {*} lat 
 * @param {*} points such as '120.133446,30.271645||120.133153,30.271669||120.133138,30.271595||'
 */
  export function  preceedPointInArea(lng, lat, points) {
    var parking_gps = points
    var gpsStringArray = parking_gps.split('||')
    var polygon = []
    for (var index in gpsStringArray) {
        var item = gpsStringArray[index]
        var point = item.split(',')
        if (point.length == 2) {
            point['lng'] = parseFloat(point[0])
            point['lat'] = parseFloat(point[1])
            polygon.push(point)
        }
    }
    console.log(polygon)
    var flag = isPointInPolygon(polygon, lng, lat)
 
    return flag;
}
 
/**
 * 某个点是否在某个区域
 */
  export function isPointInPolygon(polygon, lng, lat) {
 
    var numberOfPoints = polygon.length;
    var polygonLats = [];
    var polygonLngs = [];
    for (var i = 0; i < numberOfPoints; i++) {
        polygonLats.push(polygon[i]['lat']);
        polygonLngs.push(polygon[i]['lng']);
    }
 
    var polygonContainsPoint = false;
    for (var node = 0, altNode = (numberOfPoints - 1); node < numberOfPoints; altNode = node++) {
        if ((polygonLngs[node] > lng != (polygonLngs[altNode] > lng))
            && (lat < (polygonLats[altNode] - polygonLats[node])
                * (lng - polygonLngs[node])
                / (polygonLngs[altNode] - polygonLngs[node])
                + polygonLats[node]
            )
        ) {
            polygonContainsPoint = !polygonContainsPoint;
        }
    }
 
    return polygonContainsPoint;
}