import request from '@/utils/request'

export function add(data) {
  return request({
    url: 'api/config/cspz',
    method: 'post',
    data
  })
}

export function del(ids) {
  return request({
    url: 'api/config/cspz',
    method: 'delete',
    data: ids
  })
}

export function edit(data) {
  return request({
    url: 'api/config/cspz',
    method: 'put',
    data
  })
}

export function editBatch(data) {
  return request({
    url: 'api/config/cspz/batch',
    method: 'put',
    data
  })
}

// 不带权限
export function getconfig(params) {
  return request({
    url: 'api/config/cspz/NotAuth',
    method: 'get',
    params
  })
}

export function saveAll(data) {
  return request({
    url: 'api/config/saveAll',
    method: 'put',
    data
  })
}

export default { del, edit, add, getconfig, saveAll ,editBatch}