import request from '@/utils/request'

export function add(data) {
  return request({
    url: 'api/into',
    method: 'post',
    data
  })
}

export function del(ids) {
  return request({
    url: 'api/into/',
    method: 'delete',
    data: ids
  })
}

export function edit(data) {
    return request({
        url: 'api/into',
        method: 'put',
        data
    })
}

export function stuLeave(ids) {
  return request({
    url: 'api/into/stuLeave',
    method: 'delete',
    data: ids
  })
}

export function stuBatchLeave(params) {
  return request({
    url: 'api/into/stuBatchLeave',
    method: 'get',
    params
  })
}

export function bedChange(data) {
  return request({
    url: 'api/into/bedChange',
    method: 'put',
    data
  })
}

export function getRoomAvailableBed(id) {
  return request({
    url: 'api/into/room/'+id,
    method: 'get',
  })
}

export function getStuInfoByNoAndSex(params) {
  return request({
    url: 'api/into/getStu',
    method: 'get',
    params
  })
}
export function getTree(params) {
  return request({
    url: 'api/into/tree',
    method: 'get',
    params
  })
}

export function getStuIntoInfo(stuNo) {
  return request({
    url: 'api/into/stu/'+stuNo,
    method: 'get',
  })
}


export default { add, bedChange, del,edit ,getRoomAvailableBed,stuLeave,getStuInfoByNoAndSex,stuBatchLeave,getTree}
