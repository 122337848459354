
import request from '@/utils/request'

export function getDataNoPage(params) {
  return request({
    url: 'api/late/sign/noPage',
    method: 'get',
        params
  })
}

export function edit(data) {
  return request({
    url: 'api/late/sign/update',
    method: 'post',
    data
  })
}


export default { getDataNoPage,edit }
