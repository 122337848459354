<template>
  <div>
    <van-cell-group inset style="margin-top:17px">
      <van-cell>
        <template #title>
          <div class="room-div">
            <van-row>
              <van-col span="6">
                <div class='item-style'>
                  <div class='item-style-border'>
                    <van-image fit="contain" :src='stuViewApi + user.username' />
                  </div>
                </div>
              </van-col>
              <van-col span="15" style="line-height:28px;margin-top:6px">
                <div style="font-size: 14px; color: rgb(153, 153, 153); ">
                  <span style="font-weight: bold;font-size: 22px;color:#333333"> {{user.nickName}}</span> {{user.username}}
                </div>
                <div style="font-size: 14px; color: rgb(153, 153, 153); ">
                  {{ dict.xq_option.filter(item => { return item.value === stuIntoInfo.campusId  }).map(item => { return item.label }).toString() }} {{stuIntoInfo.floorName?' | '+stuIntoInfo.floorName :''}}{{stuIntoInfo.roomCode?' | '+stuIntoInfo.roomCode+'房间':''}}
                </div>
              </van-col>
            </van-row>
          </div>
        </template>
      </van-cell>
    </van-cell-group>
    <van-cell-group inset style="margin-top:17px">
      <div class="calendar-top-time">
        <van-icon name="arrow-left" color="#23AB72" @click="lastDate" style="margin-right:10px" /> {{ title}}
        <van-icon name="arrow" v-if="showNext" color="#23AB72" @click="nextDate" style="margin-left:10px" />
        <van-icon name="arrow" v-else color="#C8C9CC" style="margin-left:10px" />
      </div>
      <van-calendar class="calendar-main" @select="clickDate" color="rgb(35, 171, 114)" :formatter="formatter" :poppable="false" :default-date="defaultDate" :max-date="maxDate" :min-date="minDate" :show-confirm="false">
        <template #top-info="day">
          <van-icon v-if="convertTop(day)" color='#23AB72' size='17' name='success' />
        </template>
        <template #bottom-info="day">
          <div v-html="convertFooter(day)"></div>
        </template>
      </van-calendar>
      <van-cell v-if="signButton && todayInfo.signStatus ===0" style="margin-bottom: 20px;">
        <template #title>
          <div class="sign-wait" @click="sign" v-if="signTimeShow">
            <div style="font-size:21px;padding-top: 47px;">晚签打卡</div>
            <div style="font-size:14px;padding-top: 5px;">{{parseTime(currentTime, "{h}:{i}:{s}")}}</div>
          </div>
          <div class="sign-wait-disable" v-else>
            <div style="font-size:21px;padding-top: 47px;">晚签打卡</div>
            <div style="font-size:14px;padding-top: 5px;">{{parseTime(currentTime, "{h}:{i}:{s}")}}</div>
          </div>
        </template>
        <template #label>
          <div style="margin: auto;text-align: center;">
            <div class="custom-centent margin6 hidden-text100" style="color:#333333">
              <span v-if="signTimeShow">
                <van-icon name="checked" size="16" color="#23AB72" style="margin-right:8px" />签到时间：{{time[0]}}~{{time[1]}}
              </span>
              <span v-else>
                <van-icon name="clear" size="16" color="#EB7B4D" style="margin-right:8px" />签到时间：{{time[0]}}~{{time[1]}}
              </span>
            </div>
          </div>
        </template>
      </van-cell>
    </van-cell-group>
    <van-cell-group inset style="margin-top:17px;margin-bottom:20px" v-if="!signButton &&todayInfo && todayInfo.signStatus ===1">
      <van-cell>
        <template #title>
          <span style="background-color: #23AB72;margin-right:13px">&nbsp</span><span class="custom-title hidden-text">签到信息</span>
        </template>
        <template #label>
          <div class="custom-centent  hidden-text100" style="color:#333333;line-height: 27px;">
            <van-icon name="underway-o" size="15" /> 签到时间: {{parseTime(todayInfo.signTime, "{y}-{m}-{d} {h}:{i}:{s}")}}
          </div>
          <div class="custom-centent margin6 hidden-text100" style="color:#333333">
            <van-icon name="location-o" size="16" /> 签到地点： {{todayInfo.address}}
          </div>
        </template>
      </van-cell>
    </van-cell-group>
    <van-cell-group inset style="margin-top:17px;margin-bottom:20px" v-else-if="signButton && todayInfo.signStatus ===1">
      <van-cell style="margin-top:17px">
        <template #title>
          <div class="sign-ready">
            <div style="font-size:21px;padding-top: 25px;">
              <van-icon color='#23AB72' size='50' name='success' />
            </div>
            <div style="font-size:18px;color:#23AB72">签到成功 </div>
          </div>
        </template>
      </van-cell>
      <van-cell>
        <template #title>
          <span style="background-color: #23AB72;margin-right:13px">&nbsp</span>
          <span class="hidden-text">签到信息</span>
        </template>
        <template #label>
          <div class="custom-centent  hidden-text100" style="color:#333333;line-height: 30px;">
            <van-icon name="underway-o" size="15" /> 签到时间：{{parseTime(todayInfo.signTime, "{y}-{m}-{d} {h}:{i}:{s}")}}
          </div>
          <div class="custom-centent margin6 hidden-text100" style="color:#333333">
            <van-icon name="location-o" size="16" /> 签到地点：{{todayInfo.address}}
          </div>
        </template>
      </van-cell>
    </van-cell-group>
    <van-cell-group inset style="margin-top:17px;margin-bottom:20px" v-else-if="signButton &&  !todayInfo.id">
      <van-cell>
        <template #title>
          <span style="background-color: #23AB72;margin-right:13px">&nbsp</span>
          <span class="hidden-text">提示信息</span>
        </template>
        <template #label>
          <div class="custom-centent  hidden-text100" style="color:#F39F7D;line-height: 21px;">
            <van-icon name="info-o" size="16" /> 您不在本次签到范围，无需签到！
          </div>
        </template>
      </van-cell>
    </van-cell-group>

  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { mapGnextTick, etters } from "vuex";
import { stuViewApi } from "@/config/settings";
import { getStuIntoInfo } from "@/api/modules/apartment/into";
import { parseTime } from "@/utils/index";
import {
  loadBMap,
  myBMapInit,
  preceedPointInArea,
} from "@/utils/baiduLocation";
import { getconfig } from "@/api/modules/config/cspz";
import { getDataNoPage, edit } from "@/api/modules/apartment/signLate";

export default {
  name: "StuSign",
  dicts: ["xq_option"],
  data() {
    return {
      title: "",
      minDate: new Date(),
      maxDate: new Date(),
      defaultDate: new Date(),
      stuViewApi,
      stuIntoInfo: {},
      showNext: false,
      currentTime: "",
      signButton: true,
      signLoactionShow: false,
      signTimeShow: false,
      loaclAddress: "",
      address: "",
      time: [],
      signData: [],
      todayInfo: "",
    };
  },
  created() {
    getconfig({ paracode: "sign_time" }).then((res) => {
      if (res[0] && res[0].paravalue) {
        this.time = JSON.parse(res[0].paravalue);
      }
    });
    //sign_loaction
    getStuIntoInfo(this.user.username).then((res) => {
      this.stuIntoInfo = res;
    });
    this.setMinMaxDay();
    setInterval(() => {
      this.currentTime = new Date();
      // 判断是否在指定时间
      if (this.checkAuditTime(this.time[0], this.time[1])) {
        this.signTimeShow = true;
      } else {
        this.signTimeShow = false;
      }
    }, 1000);
    // 判断是否在某个范围呢
    myBMapInit().then((BMap) => {
      let myCity = new BMap.LocalCity();
      myCity.get(
        (result) => {
          let geoc = new BMap.Geocoder();
          geoc.getLocation(result.center, (res) => {
            this.address = result.center.lat + "," + result.center.lng;
            // 位置信息
            this.loaclAddress = res.address;
          });
        },
        { enableHighAccuracy: true }
      );
    });
    this.initToday(parseTime(new Date(), "{y}-{m}-{d}"));
  },
  computed: {
    ...mapGetters(["user", "roles"]),
  },
  methods: {
    parseTime,
    sign() {
      let dateStr = parseTime(new Date(), "{y}-{m}-{d}");
      let parms = {
        latLon: this.address,
        address: this.loaclAddress,
      };
      edit(parms).then((res) => {
        this.$toast.success("签到成功");
        this.initToday(parseTime(new Date(), "{y}-{m}-{d}"));
      });
    },
    clickDate(date) {
      let today = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      );

      // 选择当天
      if (today.getTime() === date.getTime()) {
        this.signButton = true;
      } else {
        this.signButton = false;
      }
      this.initToday(parseTime(date, "{y}-{m}-{d}"));
    },
    // 初始设置显示月份可选择的天数区间
    setMinMaxDay() {
      let nowYear = new Date(this.defaultDate).getFullYear();
      let nowMonth = new Date(this.defaultDate).getMonth() + 1;

      this.minDate = new Date(nowYear, nowMonth - 1, 1);
      this.maxDate = new Date(nowYear, nowMonth, 0);
      this.title = nowYear + "年" + nowMonth + "月";

      // 初始化日历
      // 日历视图初始化
      this.initCalendar(
        new Date(this.minDate).getFullYear(),
        new Date(this.minDate).getMonth() + 1
      );
    },
    // 上一个月
    lastDate() {
      let year = new Date(this.minDate).getFullYear();
      let month = new Date(this.minDate).getMonth();
      // 构造数据
      this.minDate = new Date(year, month - 1, 1);
      this.maxDate = new Date(year, month, 0);
      this.title =
        new Date(this.minDate).getFullYear() +
        "年" +
        (new Date(this.minDate).getMonth() + 1) +
        "月";

      // 默认选中
      let nowDay = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
      if (nowDay.getTime() === this.minDate.getTime()) {
        this.defaultDate = new Date();
        this.showNext = false;
        this.signButton = true;
      } else {
        this.defaultDate = new Date(year, month - 1, 1);
        this.showNext = true;
        this.signButton = false;
      }
      // 初始化默认选中
      this.initToday(parseTime(this.defaultDate, "{y}-{m}-{d}"));
      // 日历视图初始化
      this.initCalendar(
        new Date(this.minDate).getFullYear(),
        new Date(this.minDate).getMonth() + 1
      );
    },
    // 下一个月
    nextDate() {
      let year = new Date(this.minDate).getFullYear();
      let month = new Date(this.minDate).getMonth();
      // 构造数据
      this.defaultDate = new Date(year, month + 1, 1);
      this.minDate = new Date(year, month + 1, 1);
      this.maxDate = new Date(year, month + 2, 0);
      // 标题
      this.title =
        new Date(this.minDate).getFullYear() +
        "年" +
        (new Date(this.minDate).getMonth() + 1) +
        "月";
      // 默认选中  判断是否是当前月份 禁用下一个月份
      let nowDay = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
      if (nowDay.getTime() === this.minDate.getTime()) {
        this.defaultDate = new Date();
        this.showNext = false;
        this.signButton = true;
      } else {
        this.defaultDate = new Date(year, month + 1, 1);
        this.showNext = true;
        this.signButton = false;
      }
      // 初始化默认选中
      this.initToday(parseTime(this.defaultDate, "{y}-{m}-{d}"));
      // 日历视图初始化
      this.initCalendar(
        new Date(this.minDate).getFullYear(),
        new Date(this.minDate).getMonth() + 1
      );
    },
    checkAuditTime(startTime, endTime) {
      // 获取当前时间
      const date = new Date();
      // 获取当前时间的年月日
      const dataStr = `${date.getFullYear()}-${
        date.getMonth() + 1
      }-${date.getDate()} `;

      // 获取开始时间、结束时间、现在时间的时间戳
      let startDate = new Date(dataStr + startTime).getTime();
      let endDate = new Date(dataStr + endTime).getTime();
      let nowDate = date.getTime();

      const s = startDate > endDate; // 判断开始时间否大于结束时间

      if (s) [startDate, endDate] = [endDate, startDate]; // 若开始时间否大于结束时间则交换值

      // 判断现在的时间是否在开始时间和结束时间之间，若s为true则结果取反
      if (nowDate > startDate && nowDate < endDate) {
        return s ? false : true;
      } else {
        return s ? true : false;
      }
    },
    initCalendar() {
      getDataNoPage({
        createTime:
          parseTime(this.minDate, "{y}-{m}-{d} {h}:{i}:{s}") +
          "," +
          parseTime(this.maxDate, "{y}-{m}-{d} {h}:{i}:{s}"),
      }).then((res) => {
        if (res) {
          this.signData = res.map((item) => {
            item["at"] = parseTime(item.createTime, "{y}-{m}-{d}");
            return item;
          });
        }
      });
    },
    initToday(dateStr) {
      this.todayInfo = {};
      getDataNoPage({
        createTime: dateStr + " 00:00:00," + dateStr + " 23:59:59",
      }).then((res) => {
        if (res[0]) {
          this.todayInfo = res[0];
        } else {
          this.todayInfo = {};
        }
      });
    },
    // 底部数据格式化
    convertFooter(day) {
      // 当天啥也不显示
      if (
        parseTime(day.date, "{y}-{m}-{d}") ===
        parseTime(new Date(), "{y}-{m}-{d}")
      ) {
        return "";
      }

      if (this.signData) {
        let data = this.signData.filter((item) => {
          if (parseTime(day.date, "{y}-{m}-{d}") === item.at) {
            return item;
          }
        });
        if (data.length === 1) {
          if (data[0].signStatus === 1) {
            return "<span style='color:#888888'>已签</span>";
          } else if (data[0].signStatus === 0) {
            return "<span style='color:#F39F7D'>未签</span>";
          }
        }
      } else {
        return "";
      }
    },
    convertTop(day) {
      // 当天啥也不显示
      if (
        parseTime(day.date, "{y}-{m}-{d}") ===
        parseTime(new Date(), "{y}-{m}-{d}")
      ) {
        return false;
      }

      if (this.signData) {
        let data = this.signData.filter((item) => {
          if (parseTime(day.date, "{y}-{m}-{d}") === item.at) {
            return item;
          }
        });
        if (data.length === 1) {
          if (data[0].signStatus === 1) {
            return true;
          }
        }
      } else {
        return false;
      }
    },
    formatter(day) {
      return day;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .van-image__img {
  border-radius: 12px;
}
.item-style {
  width: 60px;
  height: 60px;
  background-color: rgba(242, 242, 242, 1);
  padding: 3px;
  border-radius: 12px;

  &-border {
    width: 60px;
    height: 60px;
    background-color: rgba(242, 242, 242, 1);
  }
}

.title-text {
  color: rgb(153, 153, 153);
  font-size: 13px;
}
::v-deep {
  .van-calendar__header-title {
    display: none;
  }
  .van-calendar__header-subtitle {
    display: none;
  }
  .van-calendar__month-title {
    display: none;
  }
}
.calendar-top-time {
  text-align: center;
  height: 46px;
  line-height: 44px;
  font-size: 17px;
}

.sign-wait {
  color: #ffffff;
  width: 136px;
  height: 136px;
  background: -webkit-linear-gradient(top, #27d58b, #19a56b);
  border-radius: 100px;
  text-align: center;
  margin: auto;
  &-disable {
    background: #d4d4d4;
    color: #ffffff;
    width: 136px;
    height: 136px;
    border-radius: 100px;
    text-align: center;
    margin: auto;
  }
}
.sign-ready {
  background: #f3f4f6;
  color: #23ab72;
  width: 136px;
  height: 136px;
  border-radius: 100px;
  text-align: center;
  margin: auto;
}
</style>